import { authorGradient, fitRoomGradient, seddiGradient, texturaGradient } from 'src/theme/theme';
import { AppName } from 'src/types';

export const APP_LOOKUP: Record<
  AppName,
  {
    backgroundImage: string;
    gradient: string;
    title: string;
    brandLogo: string;
    brandLogo2?: string;
  }
> = {
  author: {
    backgroundImage: 'background_image_author.png',
    gradient: authorGradient,
    title: 'Author',
    brandLogo: 'seddi_author.svg',
  },
  fitroom: {
    backgroundImage: 'background_image_fitroom.png',
    gradient: fitRoomGradient,
    title: 'Fitroom',
    brandLogo: 'seddi_fitroom.svg',
  },
  textura: {
    backgroundImage: 'background_image_textura.png',
    gradient: texturaGradient,
    title: 'Textura',
    brandLogo: 'seddi_textura.svg',
  },
  seddi: {
    backgroundImage: 'background_image_seddi.png',
    gradient: seddiGradient,
    title: 'Seddi',
    brandLogo: 'seddi_logo.svg',
    brandLogo2: 'seddi_brands_logo.svg',
  },
};
