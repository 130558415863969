import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { Button, Input, Link, ThemeProvider } from '@seddi/ui-kit';

import * as S from './AskResetEmail.styles';
import { SeddiLoginState } from '../../store/store';
import { API_KEY, AUTH_DOMAIN, TENANT_ID } from '../../constants/constants';
import { LoginUtils } from 'src/utils';
import { FormContainer } from '../formContainer';
import { SeddiTheme } from 'src/theme/SeddiTheme';
import { useSubmitOnEnter } from 'src/hooks/useSubmitOnEnter';
import { ThemeApp } from '../../theme/theme';

interface AskResetProps {
  originalUrl: string;
}

export const AskResetEmail = (props: AskResetProps) => {
  const navigate = useNavigate();
  const { originalUrl } = props;
  const loginPageUrl = useSelector((state: SeddiLoginState) => state.login.loginPageUrl);
  const [loading, setLoading] = useState(false);
  const [transitionEffect, setTransitionEffect] = useState(false);
  const [isCorrectEmailFormat, setIsCorrectEmailFormat] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const isSuccessMessage = resetMessage === 'success';

  useEffect(() => {
    if (isSuccessMessage)
      setTimeout(() => {
        setTransitionEffect(true);
      }, 200);
  }, [isSuccessMessage]);

  const handleResetEmailUpdate = (email: string) => {
    if (LoginUtils.validateEmailFormat(email)) setIsCorrectEmailFormat(true);
    else setIsCorrectEmailFormat(false);

    setResetEmail(email);
  };

  const handleEmailSubmit = () => {
    var config = {
      apiKey: API_KEY,
      authDomain: AUTH_DOMAIN,
    };
    var actionCodeSettings = {
      url: loginPageUrl,
    };
    try {
      const firebaseApp = initializeApp(config);
      const auth = getAuth(firebaseApp);
      auth.tenantId = TENANT_ID;
      sendPasswordResetEmail(auth, resetEmail, actionCodeSettings)
        .then(() => {
          // Password reset auth sent.
          setLoading(false);
          setResetMessage('success');
        })
        .catch((error) => {
          // Do not leak correct emails
          setLoading(false);
          if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-auth') {
            setResetMessage('success');
          } else {
            setResetMessage('error');
          }
        });
    } catch (e) {
      setLoading(false);
      setResetMessage('error');
    }
  };

  const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
    // e can sometimes be undefined when enter key is used
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    setResetMessage('');
    setTransitionEffect(false);
    handleEmailSubmit();
  };
  useSubmitOnEnter(onSubmit, resetEmail === '' || !isCorrectEmailFormat);

  const renderResetMessage = () => {
    // display a default error message for any errors
    if (resetMessage.includes('error')) {
      return <S.ResetMessage>{I18n.t('reset.resetError')}</S.ResetMessage>;
    }

    // display spacing if no errors
    return <div style={{ paddingTop: '1.25rem' }}></div>;
  };

  const renderHeading = () => {
    // If reset successful, let user know
    if (isSuccessMessage) {
      return <S.ResetFormHeading>{I18n.t('reset.thankYou')}</S.ResetFormHeading>;
    }

    return (
      <S.ResetFormHeading>
        {I18n.t('reset.needNewPassword')}
        <br />
        {I18n.t('reset.hereToHelp')}
      </S.ResetFormHeading>
    );
  };

  const renderSubheading = () => {
    if (isSuccessMessage) {
      return <S.ResetFormSubHeading>{I18n.t('reset.instructionsEmail')}</S.ResetFormSubHeading>;
    }

    return <S.ResetFormSubHeading>{I18n.t('reset.tellUsEmail')}</S.ResetFormSubHeading>;
  };

  const renderEmailField = () => {
    if (isSuccessMessage) {
      return null;
    }

    return (
      <S.ResetFieldContainer>
        <S.ResetFieldLabel>{I18n.t('reset.emailLabel')}</S.ResetFieldLabel>
        <Input
          type='email'
          placeholder={I18n.t('reset.emailPrompt')}
          value={resetEmail}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const email: string = (e.target as any).value;
            handleResetEmailUpdate(email);
          }}
        />
      </S.ResetFieldContainer>
    );
  };

  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    originalUrl ? (window.location.href = originalUrl) : navigate(`/login`);
  };

  // seddi.me is part of the Fitroom domain
  const brandName = originalUrl && originalUrl.includes('seddi.me') ? 'fitroom' : 'seddi';

  // TODO: not sure why we need the ThemeProvider here to get the correct colors for fitroom, when the other components on the same level do not.
  let theme = structuredClone(SeddiTheme);
  if (brandName === 'fitroom') {
    theme.color.s1 = ThemeApp.color.sf1;
    theme.color.s2 = ThemeApp.color.sf2;
  }

  // REVIEW: Duplicated components (password set)
  return (
    <ThemeProvider theme={theme}>
      <FormContainer
        brandName={brandName}
        panelPosition='right'
        mode={isSuccessMessage || loading ? 'centered' : 'sidePanel'}
        loading={loading}
      >
        <S.ResetForm $isSuccess={isSuccessMessage}>
          <S.Accordion show={!isSuccessMessage || transitionEffect}>
            <S.AccordionContent>
              {renderHeading()}
              {renderSubheading()}
              {renderEmailField()}
              {renderResetMessage()}
              {isSuccessMessage ? (
                <>
                  <Button
                    type='submit'
                    text={I18n.t('reset.resendPrompt')}
                    isDisabled={resetEmail === '' || !isCorrectEmailFormat}
                    data-dd-action-name={'resendInstructions'}
                    width='full'
                    onClick={onSubmit}
                  />
                  <S.ResetSubmitContainer $isSuccess={isSuccessMessage}>
                    <Link
                      onClick={() => {
                        originalUrl ? (window.location.href = originalUrl) : navigate(`/login`);
                      }}
                      text={I18n.t('login.logIn')}
                    />
                  </S.ResetSubmitContainer>
                </>
              ) : (
                <S.ResetSubmitContainer $isSuccess={isSuccessMessage}>
                  <>
                    <Button
                      type='submit'
                      text={I18n.t(`reset.cancel`)}
                      width='full'
                      onClick={onCancel}
                    />
                    <Button
                      type='submit'
                      text={I18n.t('reset.resetPrompt')}
                      isDisabled={resetEmail === '' || !isCorrectEmailFormat}
                      data-dd-action-name={
                        isSuccessMessage ? 'resendInstructions' : 'sendInstructions'
                      }
                      width='full'
                      onClick={onSubmit}
                    />
                  </>
                </S.ResetSubmitContainer>
              )}
            </S.AccordionContent>
          </S.Accordion>
        </S.ResetForm>
      </FormContainer>
    </ThemeProvider>
  );
};
