import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Button } from '@seddi/ui-kit';

import { FormContainer } from '../../components/formContainer';
import { CommunicationPageTypes } from './CommunicationPage.types';

export const CommunicationPage: React.FC = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  let [title, error, actionText, actionUrl]: (string | undefined)[] = [];

  switch (type) {
    case CommunicationPageTypes.SIGNUP: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.singUpError');
      actionText = I18n.t('error.backSignup');
      actionUrl = '/create-account';
      break;
    }
    case CommunicationPageTypes.ACCOUNT_EXISTS: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.accountExist');
      actionText = I18n.t('error.backSignup');
      actionUrl = '/create-account';
      break;
    }
    case CommunicationPageTypes.LOGIN: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.logInError');
      actionText = I18n.t('error.backLogin');
      actionUrl = '/login';
      break;
    }
    case CommunicationPageTypes.AUTH: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.auth');
      actionText = I18n.t('error.backLogin');
      actionUrl = '/login';
      break;
    }
    case CommunicationPageTypes.LOGOUT: {
      title = I18n.t('error.loggedOut');
      actionText = I18n.t('error.backLogout');
      actionUrl = '/login';
      break;
    }
    default:
      break;
  }

  const actionOnClick = () => {
    actionUrl &&
      navigate({
        pathname: '/',
        search: location.search,
      });
  };

  return (
    <FormContainer title={title} text={error}>
      <Button text={actionText} onClick={actionOnClick} width='full' />
    </FormContainer>
  );
};
