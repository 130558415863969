import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SignInParameters } from 'src/types/Login';
import { CreateAccount } from 'src/components/createAccount';
import { FormContainer } from 'src/components/formContainer';
import { VerifyEmail } from 'src/components/verifyEmail';
import { SeddiLoginState } from 'src/store/store';
import { CommunicationPageTypes } from 'src/features/communicationPage/CommunicationPage.types';

const Message = styled.div`
  text-align: center;
`;

const P = styled.p`
  margin: 0;
  padding: 0;
`;

export const CreatePage: React.FC<{
  signInFunctions: SignInParameters;
  originalUrl: string;
}> = ({ signInFunctions, originalUrl }) => {
  const navigate = useNavigate();
  const { name } = useSelector((state: SeddiLoginState) => state.app);

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>('');
  const [error, setError] = useState<string | boolean>(false);

  const headingText = name === 'fitroom' ? 'createAccount.welcome' : 'createAccount.getStarted';
  const title = submitted ? I18n.t('validate.title') : I18n.t(headingText);

  // Fitroom uses the seddi.me domain
  const subHeading =
    originalUrl && originalUrl.includes('seddi.me') ? 'fitroomSubHeading' : 'subHeading';
  const brandName = originalUrl && originalUrl.includes('seddi.me') ? 'fitroom' : 'seddi';

  const text = submitted ? (
    <Message>
      <P>
        {`Your account has been created for: ${newEmail}`}
        <br />
      </P>
    </Message>
  ) : (
    I18n.t(`createAccount.${subHeading}`)
  );

  if (error) {
    (error as string)?.includes('email-already-in-use')
      ? navigate(`/message/${CommunicationPageTypes.ACCOUNT_EXISTS}`)
      : navigate(`/message/${CommunicationPageTypes.SIGNUP}`);
  }

  return (
    <FormContainer
      loading={loading}
      mode={submitted ? 'centered' : 'sidePanel'}
      brandName={submitted ? brandName : undefined}
      title={title}
      text={text}
    >
      {submitted && !loading ? (
        <VerifyEmail originalUrl={originalUrl} />
      ) : (
        <CreateAccount
          signInFunctions={signInFunctions}
          originalUrl={originalUrl}
          setLoading={setLoading}
          setSubmited={setSubmitted}
          setNewEmail={setNewEmail}
          setError={setError}
          newEmail={newEmail}
        />
      )}
    </FormContainer>
  );
};
