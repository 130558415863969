let baseUrl = '';
if (process.env.REACT_APP_API_BASE_URL) {
  baseUrl = process.env.REACT_APP_API_BASE_URL;
}
export const BASE_URL = baseUrl;

let env = '';
if (process.env.REACT_APP_ENV) {
  env = process.env.REACT_APP_ENV;
}

let apiKey = '';
if (process.env.REACT_APP_API_KEY) {
  apiKey = process.env.REACT_APP_API_KEY;
}

let tenantId = '';
if (process.env.REACT_APP_TENANT_ID) {
  tenantId = process.env.REACT_APP_TENANT_ID;
}

let authDomain = '';
if (process.env.REACT_APP_AUTH_DOMAIN) {
  authDomain = process.env.REACT_APP_AUTH_DOMAIN;
}

let redirectUri = '';
if (process.env.REACT_APP_REDIRECT_URI) {
  redirectUri = process.env.REACT_APP_REDIRECT_URI;
}

let mixpanelToken = '';
if (process.env.REACT_APP_AUTHOR_MIXPANEL) {
  mixpanelToken = process.env.REACT_APP_AUTHOR_MIXPANEL;
}

export const ENV = env;
export const API_KEY = apiKey;
export const TENANT_ID = tenantId;
export const AUTH_DOMAIN = authDomain;
export const TERMS_OF_SERVICE_UPDATED_AT = Date.UTC(2020, 8, 23); // September 23, 2020
export const REDIRECT_URI = redirectUri;
export const GITLAB_UNLEASH_PROXY_BASE_URL = 'https://login-unleash-proxy-dguje7so2a-uc.a.run.app';
export const REACT_APP_AUTHOR_MIXPANEL = mixpanelToken;
